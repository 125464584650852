































import { computed, defineComponent, PropType } from '@vue/composition-api';
import {
  matchPasswordRule,
  ValidationSteps,
} from '@vf/shared/src/utils/helpers';
import useRootInstance from '../../theme/shared/useRootInstance';

export default defineComponent({
  name: 'PasswordHelper',
  props: {
    columns: {
      type: Number as PropType<number>,
      default: 1,
    },
    /** Given password */
    password: {
      type: String as PropType<string>,
      default: '',
    },
    /** Helper text for given password */
    passwordHelperText: {
      type: String as PropType<string>,
      default: 'Password must contain:',
    },
    /** Validation Steps Object with rule/translations pairs */
    validationSteps: {
      type: Object as PropType<ValidationSteps>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const ui = {
      successIcon: 'correct',
      errorIcon: 'error',
      ...(root.$themeConfig?.passwordHelper || {}),
    };

    // Split validation steps per column base on theme config
    const validationStepsColumns = computed(() => {
      const rules = Object.entries(props.validationSteps);
      const perColumn = Math.ceil(rules.length / props.columns);
      // Gets number of rules and columns and displays calculated part of rules per column.
      return [...Array(props.columns).keys()].map((col) => [
        ...rules.slice(col * perColumn, col * perColumn + perColumn),
      ]);
    });

    return {
      ui,
      validationStepsColumns,
      matchPassword: (rule) => matchPasswordRule(props.password, rule),
    };
  },
});
